// Account.scss
.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  .profile-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ee4d2d; // Màu sắc chính của Shoppee
  }

  .profile-details {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: left;

    p {
      margin: 10px 0;
      span {
        font-weight: bold;
      }
    }

    button {
      background-color: #ee4d2d;
      margin-right: 20px;
      color: white;
      border: none;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #ff3300; // Màu sắc hover của Shoppee
      }
    }

    .link {
      color: #ee4d2d;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
