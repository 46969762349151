// Contact.scss
.contact {
  padding: 40px;
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .contact-details {
    display: flex;
    justify-content: space-between;

    .contact-info,
    .contact-form {
      width: 48%;
      padding: 1rem;
      border: 1px solid #ddd;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      p {
        margin: 0.5rem 0;
      }

      label {
        display: block;
        margin-bottom: 0.5rem;
      }

      input,
      textarea {
        width: 90%;
        padding: 0.75rem;
        margin-bottom: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border-color 0.3s ease-in-out;

        &:focus {
          outline: none;
          border-color: #007bff;
        }
      }

      button {
        background-color: #007bff;
        color: white;
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
