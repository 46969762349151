
.list{
    .imgage{
        margin-left: 10px;

    }
    .case{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 50px;
    }
}