// Payment.scss
.Checkout {
    display: flex;
    height: 50vh;
    padding: 40px;
    font-family: Arial, sans-serif;
    text-align: center;
  
    h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  .layout{
    
    display: flex;
    flex-direction: row;
  }
    .customer-info,
    .payment-methods {
        
      background-color: #fff;
      padding: 50px;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
  
      h2 {
        font-size: 24px;
        margin-bottom: 15px;
      }
  
      label {
        display: block;
        margin-bottom: 20px;
        font-size: 18px;
  
        input[type="radio"]{
            margin: 5px 5px;
            
        }
        input[type="text"] {
          width: 95%;
          padding: 12px;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: 5px;
          margin-top: 5px;
        }
      }
      
    }
    .payment-methods{
            
        margin-bottom: 190px;
        margin-left: 50px;
          }
    .confirm-button {
        margin-top: auto; /* Đẩy nút lên cuối cùng của phần content-container */
      }
  
    button {
      padding: 12px 24px;
      background-color: #52dc36;
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  