.card {
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;

  .image {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;

    &:hover {
      .secondImg {
        z-index: 2;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .mainImg {
      z-index: 1;
    }

    span {
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: white;
      color: teal;
      padding: 3px 5px;
      z-index: 3;
      font-weight: 500;
      font-size: 12px;
    }
  }

  h2 {
    font-size: 25px;
    font-weight: 400;
  }

  .prices {
    display: flex;
    gap: 20px;

    h3 {
      font-size: 25px;
      font-weight: 500;

      &:first-child {
        color: rgb(255, 0, 0);
        font-size: 18px;
        text-decoration: line-through;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    .MuiSvgIcon-root {
      font-size: 24px;
      cursor: pointer;

      &:hover {
        color: teal;
      }
    }
  }
}
