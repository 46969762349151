/* SearchResultPage.scss */
.search-result-page {
    padding: 20px;
  
    h1 {
      margin-bottom: 20px;
    }
  
    .product-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
    }
}