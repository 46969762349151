// styles.scss

$primaryColor: #2196F3;
$secondaryColor: #f44336;
$backgroundLight: #f9f9f9;
$borderColor: #ccc;

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $backgroundLight;

  h1 {
    font-size: 48px;
    color: $primaryColor;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 24px;
    color: $primaryColor;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    gap: 20px;
    margin: 20px;

    input {
      width: 90%;
      padding: 16px;
      border: 1px solid $borderColor;
      border-radius: 6px;
      outline: none;
      transition: border-color 0.2s ease-in-out;
      font-size: 16px;

      &:focus {
        border-color: $primaryColor;
      }
    }

    .button-container {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }

    button {
      padding: 16px;
      border: none;
      border-radius: 6px;
      color: #ffffff;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      outline: none;
      width: 150px;
      height: 50px;

      svg {
        font-size: 24px;
      }
    }

    .email-button,
    .regis-button {
      background-color: $primaryColor;
    }

    p {
      font-size: 14px;
      text-align: center;
      color: $secondaryColor;
    }

    span {
      font-size: 14px;
      text-align: center;

      a {
        color: $primaryColor;
        font-weight: 600;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .error-message {
    color: $secondaryColor;
    font-size: 14px;
    text-align: left;
    margin-top: 5px;
  }

  .success-message {
    color: green;
    font-size: 14px;
    text-align: left;
    margin-top: 5px;
  }
}
