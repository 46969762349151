.sidebarAdmin{
    flex: 1;
    border-right: 0.5px solid rgb(235, 234, 234);
    min-height: 100vh;
    background-color: white;
    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo{
            font-size: 20px;
            font-weight: bold;
            color: darkgreen;
        }
    }
    hr{
        height: 0;
        border: 0.5px solid rgb(235, 234, 234);
    }
    .center{
        padding-left: 10px;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .title{
            font-size: 10px;
            font-weight: bold;
            color: rgb(169, 140, 140);
            margin-top: 15px;
            margin-bottom: 5px;
        }
        li{
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            &:hover{
                background-color: rgb(210, 228, 210);
            }
            .icon{
                font-size: 18px;
                color: darkgreen;
            }
            span{
                font-size: 13px;
                font-weight: 600px;
                color: rgb(169, 140, 140);
                margin-left: 10px;
            }
        }
    }
    .bottom{
        display: flex;
        align-items: center;
        margin-left: 10px;
        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid darkgreen;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: black;
            }
        }
    }
}