// About.scss
.about {
    padding: 40px;
    text-align: center;
  
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  