.footer {
    margin: 100px 200px 20px 200px;
    .top {
      display: flex;
      gap: 50px;
  
      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: justify;
        font-size: 14px;
  
        h1{
          font-size: 18px;
          font-weight: 500;
          color: #555;
        }
  
        span{
          color: gray;
        }
      }
    }
  
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      .left {
        display: flex;
        align-items: center;
  
        .logo{
          color: #2879fe;
          font-weight: bold;
          font-size: 24px;
        }
  
        .copyright{
          margin-left: 20px;
          font-size: 12px;
          color: gray;
        }
      }
      .right {
  
        img{
          height: 50px;
        }
      }
    }
}