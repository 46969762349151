.widget{
   display: flex;
   justify-content: space-between;
   flex: 1 ;
   padding: 10px;
   -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
   box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
   border-radius: 10px;
   height: 100px;

   .left ,
   .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title{
            font-weight: bold;
            font-size: 14px;
            color: rgb(182, 173, 173);
        }

        .counter{
            font-size: 28px;
            font-weight: 300px;
        }

        .link{
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage{
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive{
                color: rgb(77, 77, 219)    ;
            }
            &.negative{
                color: red    ;
            }
        }

        .icon{
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
        }

   }
}