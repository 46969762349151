.update-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  h1 {
    margin-bottom: 20px;
  }

  form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    .form-group {
      margin-bottom: 15px;

      label {
        font-weight: bold;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea,
      select,
      input[type="date"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
      }

      select {
        height: 40px;
      }

      input[type="date"] {
        height: 32px;
      }
    }

    button[type="submit"] {
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 10px 15px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
